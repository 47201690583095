.root {
  padding: 20px;
}
@media (max-width: 768px) {
  .root {
    padding: 4px 8px 4px;
  }
}
.listMainArea {
  height: 100%;
  display: flex;
}

.leftSide {
  height: 100%;
  flex-grow: 3;
  border-right: 1px solid rgb(230, 230, 230);
}
@media (max-width: 768px) {
  .leftSide {
    height: calc(100% - 25px);
  }
}

.rightSide {
  height: 100%;
  flex-grow: 2;
  width: 50%;
  max-width: 600px;
}

@media (max-width: 960px) {
  .rightSide {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #2525254d;
    z-index: 600;
    width: 100%;
    max-width: initial;
  }
}

.infoPaper {
  padding: 32px 24px;
  margin: 8px;
  border-radius: 15px !important;
}

@media (max-width: 960px) {
  .infoPaper {
    height: calc(100% - 16px);
  }
}

.closeInfo {
  display: none !important;
}

@media (max-width: 960px) {
  .closeInfo {
    display: block !important;
    position: absolute !important;
    right: 16px;
    top: 16px;
  }
}

.listContainer {
  height: calc(100% - 73px);
}
.filtersListContainer {
  height: calc(100% - 104px);
}
@media screen and (max-width: 768px) {
  .listContainer {
    height: calc(100% - 46px);
  }
  .filtersListContainer {
    height: calc(100% - 80px);
  }
}

.noHeaderOffset {
  height: calc(100%);
}

.title {
  margin-top: 15px !important;
}

.button {
  float: right !important;
  margin-left: 24px !important;
}

.emptyListImage {
  height: 200px;
  width: 100%;
  background-size: 70%;
  background: url("./emptyList.svg") no-repeat center bottom;
}

.header {
  font-size: 16px;
  color: gray;
}
@media screen and (max-width: 600px) {
  .header {
    font-size: 16px;
  }
}

.filter {
  cursor: pointer;
  margin-right: 16px;
  color: gray;
}
@media screen and (max-width: 600px) {
  .filter {
    margin-right: 8px;
  }
}

.filter:hover,
.filter.active {
  color: #000;
}
.filter.active {
  font-weight: 600;
}

.subHeader {
  font-size: 16px;
  color: gray;
}
