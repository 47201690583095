.okhati-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .hideInNarrowView {
    @media screen and (max-width: 600px) {
      display: none !important;
    }
  }

  .alwaysHidden {
    display: none !important;
  }

  .ReactVirtualized__List {
    &:focus {
      outline: none;
    }
  }

  .Controlled.Input.CheckBox {
    margin: 0;
    height: initial;

    > div.check {
      border-radius: 2px;
      margin-top: 0px;
      margin: 0;
    }

    > div.check.checked {
      background-size: 8px 6px;
      background-repeat: no-repeat;
      background-position: 2px 3px;
    }
  }

  .okhati-list-header {
    display: flex;
    padding-bottom: 8px;
    padding-top: 8px;
    font-size: 10px;
    line-height: 12px;
    border-bottom: 1px solid #dddfdf;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
    flex-basis: inherit;
    //this will prevent misalignment/layout shift of header and data columns
    // when scrollbar toggles (hidden/visible)
    overflow-y: hidden;
    scrollbar-gutter: stable;
    flex-shrink: 0;

    @supports (-webkit-touch-callout: none) {
      margin-bottom: 40px;
      /* CSS specific to iOS devices */
    }

    .okhati-list-headercell {
      cursor: pointer;
      font-family: "museo-sans-condensed", sans-serif;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      flex-basis: 200px;
      display: flex;
      align-items: center;

      &:first-child {
        flex-basis: 160px;
        flex-grow: initial;
      }

      &:nth-child(2) {
        flex-grow: 1;
      }

      .caret {
        height: 8px;
        width: 16px;
        display: inline-block;

        &.asc {
          background: url(./grid-asc.png) no-repeat center;
        }

        &.desc {
          background: url(./grid-desc.png) no-repeat center;
        }
      }

      &.actions {
        flex: 0 0 20px;
      }
    }
  }

  .okhati-list-rows {
    flex-grow: 1;
    height: 100%;
    .ReactVirtualized__Grid.ReactVirtualized__List {
      scrollbar-gutter: stable;
    }
  }

  .okhati-list-row {
    display: flex;
    border-bottom: 1px solid #dddfdf;
    cursor: pointer;

    &.active,
    &:hover {
      background: #00965526;
    }

    .okhati-list-rowcell {
      flex-basis: 200px;
      height: 50px;
      align-items: center;
      display: flex;

      &:first-child {
        flex-basis: 160px;
        flex-grow: initial;
      }

      &:nth-child(2) {
        flex-grow: 1;
      }
    }
  }
}

.okhati-list-menu {
  &.okhati-list-menu-active {
    .okhati-list-menu-items {
      visibility: visible;
    }
  }

  .okhati-list-menu-handle {
    background: url(grid-menu-burger.svg) no-repeat center;
    width: 16px;
    height: 16px;

    &:hover {
      background: url(grid-menu-burger-hover.svg) no-repeat center;
    }
  }

  .okhati-list-menu-items {
    visibility: hidden;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      top: 2px;
      bottom: auto;
      border-style: solid;
      border-width: 0 8px 8px;
      border-color: #eaeaea transparent;
    }

    position: absolute;
    right: 0;
    > div {
      position: relative;
      top: 10px;
      right: 0;
      background: #fafafa;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.38);

      .okhati-list-menu-item {
        color: #3b3c3c;
        display: block;
        padding: 6px 8px;
        z-index: 4;
        background: #fafafa;
        position: relative;
        // white-space: nowrap;

        //width: 100px;
        &:hover {
          background: #9fdee3;
          text-decoration: none;
        }
      }
    }
  }
}
