.SearchList:hover {
  background-color: #e6e6e6;
}

.selectedArticleRoot {
  position: relative;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 135px);
  overflow-y: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0.4em;
  }

  p {
    font-size: 14px;
    padding: 0.25rem 0;
  }

  p img {
    width: 100%;
    height: auto;
    margin: 0.5rem 0;
  }

  ol,
  ul {
    font-size: 14px;
    padding-left: 0;
    list-style-position: inside;
  }

  * > div {
    padding: 1rem;
    position: relative !important;
    overflow: hidden !important;
    padding-top: 56.25% !important;
  }

  * > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    top: 0;
    left: 0;
    margin: 0.5rem 0;
  }
}
