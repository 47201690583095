.patientFlowReportList {
  height: calc(100vh - 290px);
}

.patientAssessmentsReportList {
  height: calc(100vh - 230px);
}

.patientFlowReportList,
.patientAssessmentsReportList {
  overflow-y: hidden;

  .okhati-list-row {
    padding-right: 20px;
  }

  .okhati-list-headercell:nth-child(2) {
    flex-grow: initial !important;
  }

  .okhati-list-headercell:nth-child(4) {
    flex-grow: 1;
  }

  .okhati-list-rowcell:nth-child(2) {
    flex-grow: initial !important;
  }

  .okhati-list-rowcell:nth-child(4) {
    flex-grow: 1;
  }
}

.hmislablist {
  height: calc(100vh - 230px);
  overflow-y: hidden;

  .okhati-list-headercell:nth-child(1),
  .okhati-list-rowcell:nth-child(1) {
    flex-grow: 1 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
  }

  .okhati-list-headercell:nth-child(2),
  .okhati-list-rowcell:nth-child(2) {
    flex-basis: 200px !important;
  }

  .okhati-list-headercell:nth-child(3),
  .okhati-list-rowcell:nth-child(3) {
    flex-basis: 300px;
  }

  .okhati-list-headercell:nth-child(4),
  .okhati-list-rowcell:nth-child(4),
  .okhati-list-headercell:nth-child(5),
  .okhati-list-rowcell:nth-child(5) {
    flex-basis: 200px;
  }

  .okhati-list-rowcell:nth-child(5) {
    margin-right: 20px;
  }
}

.salesReportList {
  .okhati-list-row {
    padding-right: 32px;
  }

  .okhati-list-headercell:not(:last-child):not(:nth-last-child(2)),
  .okhati-list-rowcell:not(:last-child) {
    margin-right: 8px;
  }

  .okhati-list-headercell:nth-child(1),
  .okhati-list-rowcell:nth-child(1) {
    flex: 1 1 200px !important;
  }

  .okhati-list-headercell:nth-child(2),
  .okhati-list-rowcell:nth-child(2) {
    flex: 0 1 200px !important;
  }

  .okhati-list-headercell:nth-child(3),
  .okhati-list-rowcell:nth-child(3) {
    flex-basis: 200px;
  }

  .okhati-list-headercell:nth-child(4),
  .okhati-list-rowcell:nth-child(4) {
    flex-basis: 200px;
  }

  .okhati-list-headercell:nth-child(5),
  .okhati-list-rowcell:nth-child(5) {
    flex: 0 0 200px;
  }

  .okhati-list-headercell:nth-child(6),
  .okhati-list-rowcell:nth-child(6) {
    flex: 0 0 200px;
  }

  .okhati-list-headercell:nth-last-child(2) {
    margin-right: 12px;
  }

  .okhati-list-headercell:nth-last-child(-n + 4),
  .okhati-list-rowcell:nth-last-child(-n + 3) {
    justify-content: flex-end;
    flex: 0 0 150px;
  }
}

.clientReportList {
  padding-bottom: 40px;

  .okhati-list-row {
    padding-right: 22px;
  }

  .okhati-list-headercell:nth-child(1),
  .okhati-list-rowcell:nth-child(1) {
    flex-basis: 100px !important;
  }

  .okhati-list-headercell:nth-child(2),
  .okhati-list-rowcell:nth-child(2) {
    flex: 1 1 200px !important;
  }

  .okhati-list-headercell:nth-child(3),
  .okhati-list-rowcell:nth-child(3) {
    flex-basis: 200px;
  }

  .okhati-list-headercell:nth-child(4),
  .okhati-list-rowcell:nth-child(4) {
    flex-basis: 200px;
  }

  .okhati-list-headercell:nth-child(5),
  .okhati-list-rowcell:nth-child(5) {
    flex: 0 0 200px;
  }

  .okhati-list-headercell:nth-child(6),
  .okhati-list-rowcell:nth-child(6) {
    flex: 0 0 200px;
    margin-right: 8px;
  }

  .okhati-list-headercell:nth-child(7),
  .okhati-list-rowcell:nth-child(7) {
    flex: 0 0 150px;
    margin-right: 8px;
  }
}

.summaryReportList {
  .okhati-list-row {
    padding-right: 20px;
  }

  .okhati-list-headercell:first-child,
  .okhati-list-rowcell:first-child {
    flex: 1 1 400px !important;
  }

  .okhati-list-headercell,
  .okhati-list-rowcell {
    flex: 0 1 180px;
  }

  .okhati-list-headercell:nth-last-child(-n + 9),
  .okhati-list-rowcell:nth-last-child(-n + 8) {
    justify-content: flex-end;
  }

  .okhati-list-segment-sumary {
    width: 100%;
    background-color: #e6e6e6;
    align-items: center;

    .okhati-list-row {
      width: 100%;
      padding-right: 0;
    }
  }
}

div.salesByServiceReportList {
  .okhati-list-row {
    &:not(.okhati-list-segment-sumary) {
      padding-right: 20px;
    }
  }

  .okhati-list-headercell:first-child,
  .okhati-list-rowcell:first-child {
    flex: 1 1 350px !important;
    margin-right: 6px;
  }

  .okhati-list-headercell:nth-child(2),
  .okhati-list-rowcell:nth-child(2) {
    flex: 0 1 170px !important;
    margin-right: 6px;
  }

  .okhati-list-headercell:nth-child(3),
  .okhati-list-rowcell:nth-child(3) {
    flex: 0 1 200px;
    margin-right: 6px;
  }

  .okhati-list-headercell:nth-child(4),
  .okhati-list-rowcell:nth-child(4) {
    flex: 0 0 130px;
    margin-right: 6px;
  }

  .okhati-list-headercell:nth-child(5),
  .okhati-list-rowcell:nth-child(5) {
    flex: 0 0 200px;
  }

  .okhati-list-headercell:nth-child(6),
  .okhati-list-rowcell:nth-child(6) {
    flex-basis: 200px;
  }

  .okhati-list-headercell:nth-child(7),
  .okhati-list-rowcell:nth-child(7) {
    flex: 0 1 200px;
  }

  .okhati-list-headercell:nth-child(8),
  .okhati-list-rowcell:nth-child(8) {
    padding-right: 20px;
    justify-content: flex-end;
    flex: 0 1 150px;
  }

  .okhati-list-headercell:nth-child(9),
  .okhati-list-rowcell:nth-child(9) {
    justify-content: flex-end;
    flex: 0 1 150px;
  }

  .okhati-list-headercell:nth-child(10),
  .okhati-list-rowcell:nth-child(10) {
    padding-right: 10px;
    flex: 0 1 200px;
    justify-content: flex-end;
  }

  .okhati-list-headercell:nth-last-child(2),
  .okhati-list-rowcell:nth-last-child(1) {
    padding-right: 10px;
    flex: 0 1 200px;
    justify-content: flex-end;
  }

  .okhati-list-headercell:last-child {
    padding-right: 0;
  }
}

.stockAuditReportList {
  height: calc(100vh - 300px);
  margin-bottom: 40px;

  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:nth-child(n + 5):not(:last-child) {
          justify-content: flex-end;
        }

        &:nth-last-child(2) {
          padding-right: 10px;
        }
      }
    }

    .okhati-list-row {
      &:not(.okhati-list-segment-sumary) {
        padding-right: 20px;
      }

      .okhati-list-rowcell {
        &:nth-child(n + 5) {
          justify-content: flex-end;
        }

        &:last-child {
          padding-right: 10px;
        }
      }
    }
  }
}

.stockCashFlowReportList {
  .okhati-list-row {
    &:not(.okhati-list-segment-sumary) {
      padding-right: 20px;
    }
  }

  .okhati-list-headercell:nth-child(1) {
    justify-content: flex-start;
    padding-right: 10px;
    flex-grow: 1 !important;
  }

  .okhati-list-headercell:nth-child(4) {
    padding-right: 10px;
  }

  .okhati-list-headercell:nth-child(5) {
    padding-right: 10px;
  }

  .okhati-list-headercell:nth-child(6) {
    justify-content: flex-end;
    padding-right: 10px;
  }

  .okhati-list-headercell:nth-child(7) {
    justify-content: flex-end;
    padding-right: 10px;
  }

  .okhati-list-headercell:nth-child(8) {
    padding-right: 10px;
    justify-content: flex-end;
  }

  .okhati-list-headercell:nth-child(9) {
    text-align: right;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .okhati-list-headercell:nth-child(10) {
    padding-right: 10px;
    justify-content: flex-end;
  }

  .okhati-list-headercell:nth-child(11),
  .okhati-list-headercell:nth-last-child(2) {
    padding-right: 10px;
    justify-content: flex-end;
  }

  .okhati-list-rowcell:nth-child(1) {
    justify-content: flex-start;
    padding-right: 10px;
    flex-grow: 1 !important;
  }

  .okhati-list-rowcell:nth-child(4) {
    padding-right: 10px;
  }

  .okhati-list-rowcell:nth-child(5) {
    justify-content: flex-start;
    text-align: right;
    padding-right: 10px;
  }

  .okhati-list-rowcell:nth-child(6) {
    justify-content: flex-end;
    padding-right: 10px;
  }

  .okhati-list-rowcell:nth-child(7) {
    padding-right: 10px;
    justify-content: flex-end;
  }

  .okhati-list-rowcell:nth-child(8) {
    padding-right: 10px;
    justify-content: flex-end;
  }

  .okhati-list-rowcell:nth-child(9) {
    padding-right: 10px;
    justify-content: flex-end;
  }

  .okhati-list-rowcell:nth-child(10) {
    padding-right: 10px;
    justify-content: flex-end;
  }

  .okhati-list-rowcell:nth-child(11) {
    padding-right: 10px;
    justify-content: flex-end;
  }

  .okhati-list-rowcell:nth-child(12) {
    justify-content: flex-end;
    padding-right: 10px;
  }
}

.dueReportList {
  .okhati-list-row:not(.okhati-list-segment-sumary) {
    padding-right: 20px;
  }

  .okhati-list-headercell:first-child,
  .okhati-list-rowcell:first-child {
    flex: 0 1 200px;
  }

  .okhati-list-headercell:nth-child(2),
  .okhati-list-headercell:nth-child(3) {
    flex-grow: 1;
  }

  .okhati-list-rowcell:nth-child(2),
  .okhati-list-rowcell:nth-child(3) {
    flex-grow: 1;
  }

  .okhati-list-headercell:nth-child(4),
  .okhati-list-headercell:nth-child(5),
  .okhati-list-rowcell:nth-child(4),
  .okhati-list-rowcell:nth-child(5),
  .okhati-list-rowcell:nth-child(6),
  .okhati-list-headercell:nth-child(6) {
    flex: 0 1 150px;
  }

  .okhati-list-headercell:nth-child(7),
  .okhati-list-rowcell:nth-child(7) {
    flex: 0 1 150px;
    padding-right: 5px;
    justify-content: flex-end;
  }

  .okhati-list-headercell:nth-child(8) {
    flex: 0 1 150px;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .okhati-list-rowcell:nth-child(8) {
    flex: 0 1 150px;
    justify-content: flex-end;
    padding-right: 10px;
  }
}

.receiptReportList {
  .okhati-list-row:not(.okhati-list-segment-sumary) {
    padding-right: 20px;
  }

  .okhati-list-headercell:first-child,
  .okhati-list-rowcell:first-child {
    flex: 0 1 100px;
  }

  .okhati-list-headercell:nth-child(2),
  .okhati-list-rowcell:nth-child(2) {
    flex: 1 1 100px !important;
  }

  .okhati-list-headercell:nth-child(9) {
    justify-content: flex-end;
    padding-right: 10px;
    flex-basis: 140px;
  }

  .okhati-list-headercell:nth-child(8) {
    flex-basis: 120px;
  }

  .okhati-list-headercell:nth-child(7) {
    flex-basis: 180px;
  }

  .okhati-list-headercell:nth-child(6) {
    flex-basis: 150px !important;
  }

  .okhati-list-headercell:nth-child(5) {
    flex-basis: 210px !important;
  }

  .okhati-list-headercell:nth-child(3),
  .okhati-list-rowcell:nth-child(3) {
    flex: 0 1 200px;
  }

  .okhati-list-rowcell:nth-child(8) {
    flex-basis: 120px;
  }

  .okhati-list-rowcell:nth-child(7) {
    flex-basis: 180px;
  }

  .okhati-list-rowcell:nth-child(6) {
    flex-basis: 150px !important;
  }

  .okhati-list-rowcell:nth-child(5) {
    flex-basis: 210px !important;
  }

  .okhati-list-rowcell:last-child {
    justify-content: flex-end;
    flex-basis: 140px;
    padding-right: 10px;
  }
}

.commissionList {
  .okhati-list-row:not(.okhati-list-segment-sumary) {
    padding-right: 20px;
  }

  .okhati-list-headercell:nth-last-child(2) {
    flex-basis: 180px;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .okhati-list-rowcell:last-child {
    flex-basis: 180px;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .okhati-list-headercell:nth-child(5),
  .okhati-list-headercell:nth-child(6) {
    flex-basis: 180px !important;
    justify-content: flex-end;
  }

  .okhati-list-rowcell:nth-child(5),
  .okhati-list-rowcell:nth-child(6) {
    justify-content: flex-end;
    flex-basis: 180px !important;
  }

  .okhati-list-headercell:nth-child(4) {
    flex-basis: 180px !important;
  }

  .okhati-list-rowcell:nth-child(4) {
    flex-basis: 180px !important;
  }

  .okhati-list-headercell:nth-child(3) {
    flex-basis: 240px !important;
  }

  .okhati-list-rowcell:nth-child(3) {
    flex-basis: 240px !important;
  }
}

.serviceProviderChargesReportList {
  height: calc(100vh - 240px) !important;

  .okhati-list-row:not(.okhati-list-segment-sumary) {
    padding-right: 20px;
  }

  .okhati-list-headercell {
    &:nth-child(7) {
      flex-basis: 210px;
      justify-content: flex-end;
      text-align: right;
      margin-right: 20px;
    }

    &:nth-child(6) {
      flex-basis: 210px !important;
    }

    &:nth-child(5) {
      flex-basis: 200px !important;
    }

    &:nth-child(4) {
      flex-basis: 240px !important;
    }

    &:nth-child(3) {
      flex-basis: 150px !important;
      padding-right: 30px;
    }

    &:nth-child(2) {
      flex: 1 1 250px;
    }

    &:nth-child(1) {
      flex-basis: 250px !important;
    }
  }

  .okhati-list-rowcell {
    &:last-child {
      flex-basis: 210px;
      justify-content: flex-end;
      margin-right: 20px;
    }

    &:nth-child(6) {
      flex-basis: 210px !important;
      justify-content: flex-start;
    }

    &:nth-child(5) {
      flex-basis: 200px !important;
    }

    &:nth-child(4) {
      flex-basis: 240px !important;
    }

    &:nth-child(3) {
      flex-basis: 150px !important;
      padding-right: 30px;
    }

    &:nth-child(2) {
      flex: 1 1 250px;
    }

    &:nth-child(1) {
      flex-basis: 250px !important;
    }
  }
}

.supplierLedgerReportList {
  height: calc(100vh - 280px);
  overflow-y: hidden;

  .okhati-list-headercell {
    &:nth-child(n) {
      flex-basis: 150px;
    }

    &:first-child {
      flex-basis: 160px;
    }

    &:nth-child(2) {
      flex-grow: 1;
    }

    &:nth-child(9),
    &:nth-child(8),
    &:nth-child(7) {
      flex-basis: 150px;
      justify-content: flex-end;
    }

    &:nth-child(6) {
      flex-basis: 120px;
    }

    &:nth-last-child(2) {
      padding-right: 10px;
    }
  }

  .okhati-list-row {
    &:not(.okhati-list-segment-sumary) {
      padding-right: 20px;
    }

    .okhati-list-rowcell {
      &:nth-child(n) {
        flex-basis: 150px;
      }

      &:first-child {
        flex-basis: 160px;
      }

      &:nth-child(2) {
        flex-grow: 1;
      }

      &:nth-child(9),
      &:nth-child(8),
      &:nth-child(7) {
        flex-basis: 150px;
        justify-content: flex-end;
      }

      &:nth-child(6) {
        flex-basis: 120px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }
}

.labReportList {
  height: 565px !important;

  .okhati-list-container {
    .okhati-list-row {
      padding-right: 20px;
    }

    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-basis: 150px;
        }

        &:nth-child(2) {
          flex-basis: 200px;
        }

        &:nth-child(3) {
          flex-grow: 1;
        }

        &:nth-child(4) {
          flex-basis: 180px;
        }

        &:nth-child(5) {
          flex-basis: 150px;
        }

        &:nth-child(6) {
          flex-basis: 180px;
        }

        &:nth-child(7) {
          flex-basis: 150px;
        }

        &:nth-child(8) {
          flex-basis: 150px;
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell {
        &:first-child {
          flex-basis: 150px;
        }

        &:nth-child(2) {
          flex-basis: 200px;
        }

        &:nth-child(3) {
          flex-grow: 1;
        }

        &:nth-child(4) {
          flex-basis: 180px;
        }

        &:nth-child(5) {
          flex-basis: 150px;
        }

        &:nth-child(6) {
          flex-basis: 180px;
        }

        &:nth-child(7) {
          flex-basis: 150px;
        }

        &:nth-child(8) {
          flex-basis: 150px;
        }
      }
    }
  }
}

.smsReportList {
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-basis: 260px;
        }

        &:nth-child(2) {
          flex-basis: 260px;
          flex-grow: 0;
        }

        &:nth-child(3) {
          flex-basis: 260px;
          flex-grow: 0;
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell:first-child {
        flex-basis: 260px;
      }

      .okhati-list-rowcell:nth-child(2) {
        flex-basis: 260px;
        flex-grow: 0;
      }

      .okhati-list-rowcell:nth-child(3) {
        flex-basis: 260px;
        flex-grow: 0;
      }

      .okhati-list-rowcell:last-child {
        flex-grow: 1;
      }
    }
  }
}

.clientLedgerReport {
  .okhati-list-row {
    padding-right: 20px;
  }
}

.bankReconciliationReport {
  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-basis: 260px;
        }

        &:nth-child(2) {
          flex-basis: 260px;
          flex-grow: 1;
        }

        &:nth-child(3) {
          flex-basis: 150px;
        }

        &:nth-child(4) {
          flex-basis: 260px;
          padding-right: 50px;
          display: flex;
          flex-direction: row-reverse;
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell:first-child {
        flex-basis: 260px;
      }

      .okhati-list-rowcell:nth-child(2) {
        flex-basis: 260px;
        flex-grow: 1;
      }

      .okhati-list-rowcell:nth-child(3) {
        flex-basis: 150px;
      }

      .okhati-list-rowcell:nth-child(4) {
        flex-basis: 260px;
        padding-right: 50px;
        display: flex;
        flex-direction: row-reverse;
      }

      .okhati-list-rowcell:last-child {
        flex-grow: 1;
      }
    }
  }
}

@mixin totalBarStyle {
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 0;
  overflow-y: hidden;
  scrollbar-gutter: stable;

  @media screen and (min-width: 960px) {
    width: calc(100% - 84px);
  }
}

.totalBar {
  @include totalBarStyle;
}

.salesReportTotalBarStyle {
  @include totalBarStyle;
  justify-content: unset;

  .totalTxt {
    flex-grow: 1;
  }

  padding-right: 32px;

  .totalValueCell {
    text-align: right;

    &:nth-child(2) {
      flex: 0 0 300px;
    }

    &:nth-child(3) {
      flex: 0 0 200px;
    }

    &:last-child {
      flex: 0 0 150px;
    }
  }
}

@media screen and (max-width: 960px) {
  .totalBar {
    width: 100%;
  }
}

@mixin textEllipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stockTransactionReport {
  height: calc(100vh - 260px);

  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:first-child {
          flex-grow: 1;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }

        &:nth-child(3) {
          flex-basis: 100px;
        }

        &:nth-child(4) {
          flex-basis: 150px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          justify-content: flex-end;
          flex-basis: 150px;
        }

        &:nth-child(7) {
          justify-content: flex-end;
          flex-basis: 100px;
        }

        &:nth-child(8) {
          justify-content: flex-end;
          flex-basis: 150px;
        }

        &:nth-child(9) {
          justify-content: flex-end;
          flex-basis: 150px;
        }

        &:nth-child(10) {
          justify-content: flex-end;
          flex-basis: 150px;
          padding-right: 10px;
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell {
        &:first-child {
          flex-grow: 1;
          padding-right: 10px;
          @include textEllipse;
        }

        &:nth-child(2) {
          width: 200px;
          @include textEllipse;
        }

        &:nth-child(3) {
          flex-basis: 100px;
        }

        &:nth-child(4) {
          flex-basis: 150px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          flex-basis: 150px;
          justify-content: flex-end;
        }

        &:nth-child(7) {
          flex-basis: 100px;
          justify-content: flex-end;
        }

        &:nth-child(8) {
          justify-content: flex-end;
          flex-basis: 150px;
        }

        &:nth-child(9) {
          justify-content: flex-end;
          flex-basis: 150px;
        }

        &:nth-child(10) {
          justify-content: flex-end;
          flex-basis: 150px;
          margin-right: 20px;
        }
      }
    }
  }
}

.stockSummaryReport {
  height: calc(100vh - 250px);

  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:not(:last-child) {
          padding-right: 10px;
        }

        &:first-child {
          flex-grow: 1;
        }

        &:nth-child(n + 2):nth-child(-n + 9) {
          flex-grow: 0 !important;
          flex-basis: 150px;
        }

        &:nth-child(n + 4):nth-child(-n + 9) {
          justify-content: flex-end;
        }
      }
    }

    .okhati-list-row:not(.okhati-list-segment-sumary) {
      padding-right: 20px;
    }

    .okhati-list-row.okhati-list-segment-sumary {
      span {
        display: flex;
        justify-content: flex-end;
        flex-grow: 0 !important;
        flex-basis: 150px;
      }

      p {
        padding-right: 10px;

        &:first-child {
          flex-basis: 160px;
          flex-grow: 1;
        }

        &:nth-child(n + 2) {
          display: flex;
          justify-content: flex-end;
          flex-grow: 0 !important;
          flex-basis: 150px;
        }
      }
    }

    .okhati-list-row {
      .okhati-list-rowcell {
        padding-right: 10px;

        &:first-child {
          flex-grow: 1;
          @include textEllipse;
        }

        &:nth-child(n + 2):nth-child(-n + 9) {
          flex-grow: 0 !important;
          flex-basis: 150px;
          @include textEllipse;
        }

        &:nth-child(n + 4):nth-child(-n + 9) {
          justify-content: flex-end;
        }
      }
    }
  }
}

.stockHistoryReport {
  height: calc(100vh - 240px) !important;

  .okhati-list-row:not(.okhati-list-segment-sumary) {
    padding-right: 30px;
  }

  .okhati-list-rowcell {
    &:last-child {
      flex-basis: 250px;
      justify-content: flex-end;
    }

    &:nth-child(5) {
      flex-basis: 200px !important;
      background-color: red !important;
    }

    &:nth-child(4) {
      flex-basis: 240px !important;
    }

    &:nth-child(3) {
      flex-basis: 150px !important;
      justify-content: flex-end;
      padding-right: 30px;
    }

    &:nth-child(2) {
      flex: 1 1 250px;
    }

    &:nth-child(1) {
      flex-basis: 250px !important;
    }
  }

  .okhati-list-headercell {
    &:nth-child(6) {
      flex-basis: 250px;
      justify-content: flex-end;
      text-align: right;
      margin-right: 10px;
    }

    &:nth-child(5) {
      flex-basis: 200px !important;
    }

    &:nth-child(4) {
      flex-basis: 240px !important;
    }

    &:nth-child(3) {
      flex-basis: 150px !important;
      justify-content: flex-end;
      padding-right: 30px;
    }

    &:nth-child(2) {
      flex: 1 1 250px;
    }

    &:nth-child(1) {
      flex-basis: 250px !important;
    }
  }
}

.stockExpiryReport {
  height: calc(100vh - 260px);

  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:nth-child(3) {
          flex-grow: 1 !important;
        }

        &:not(:last-child) {
          padding-right: 10px;
          flex-basis: 150px;
          flex-grow: 0;
        }

        &:nth-child(n + 6):nth-child(-n + 11) {
          justify-content: flex-end;
        }
      }
    }

    .okhati-list-row {
      &:not(.okhati-list-segment-sumary) {
        padding-right: 20px;
      }

      .okhati-list-rowcell {
        flex-basis: 150px;
        flex-grow: 0 !important;
        padding-right: 10px;

        &:nth-child(3) {
          flex-grow: 1 !important;
        }

        &:nth-child(n + 6):nth-child(-n + 11) {
          justify-content: flex-end;
        }
      }
    }
  }
}

.stockLedgerReport {
  height: calc(100vh - 260px);

  .okhati-list-container {
    .okhati-list-header {
      .okhati-list-headercell {
        &:not(:last-child) {
          padding-right: 10px;
        }

        &:nth-child(4) {
          flex-grow: 1;
        }

        &:nth-child(n + 2):nth-child(-n + 8):not(:nth-child(4)) {
          flex-grow: 0 !important;
          flex-basis: 250px;
        }

        &:nth-child(n + 6):nth-child(-n + 9) {
          justify-content: flex-end;
        }
      }
    }

    .okhati-list-row {
      padding-right: 20px;

      .okhati-list-rowcell {
        padding-right: 10px;

        &:nth-child(4) {
          flex-grow: 1;
          @include textEllipse;
        }

        &:nth-child(n + 2):nth-child(-n + 8):not(:nth-child(4)) {
          flex-grow: 0 !important;
          flex-basis: 250px;
          @include textEllipse;
        }

        &:nth-child(n + 6):nth-child(-n + 8) {
          justify-content: flex-end;
        }
      }
    }
  }
}
