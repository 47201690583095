.ifrm {
  width: 0px;
  height: 0px;
  display: contents;
}

.print-container {
  padding: 20px 40px;
  width: 100%;
}

.logo {
  height: 35px;
  width: auto;
}

.assessment-image {
  height: 200px;
  width: auto;
}

.pt-2 {
  padding-top: 20px;
}
